import { Button } from "react-bootstrap";

interface NavBarLoggedOutViewProps {
  onSignUpClicked: () => void;
  onLoginClicked: () => void;
}

const NavBarLoggedOutView = ({
  onSignUpClicked,
  onLoginClicked,
}: NavBarLoggedOutViewProps) => {
  return (
    <div className="mb-2">
      <div className="btn btn-outline-primary me-2" onClick={onSignUpClicked}>
        Sign Up
      </div>
      <div className="btn btn-primary me-2" onClick={onLoginClicked}>
        Log In
      </div>
    </div>
  );
};

export default NavBarLoggedOutView;
