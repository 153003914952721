import { useState } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PaginationProps {
  onPageChange: (pageNumber: number) => void;
  totalPages: number;
}

const PaginationComponent = ({ onPageChange, totalPages }: PaginationProps) => {
  const [active, setActive] = useState<number>(1);
  let items: JSX.Element[] = [];

  const handlePageClick = (pageNumber: number): void => {
    // Handle the click event, such as updating the active page
    setActive(pageNumber);
    onPageChange(pageNumber);
  };

  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        // disabled={number > 1}
        key={number}
        active={number === active}
        onClick={() => handlePageClick(number)} // Add onClick event handler
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div>
      {}
      <div className=" d-flex justify-content-center w-100 mt-5">
        {totalPages ? "Pages" : ""}
      </div>
      <div className="d-flex justify-content-center w-100 mt-2">
        <Pagination>{items}</Pagination>
        <br />
      </div>
    </div>
  );
};

export default PaginationComponent;
