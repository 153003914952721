import axios from "axios";
import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { Note, NoteObj } from "../models/note";
import { User } from "../models/user";

const port = process.env.REACT_APP_API_PORT || 5000;

let baseURL = "";
if (process.env.NODE_ENV === "production") {
  baseURL = "https://notes.api.omkargosavi.com";
} else {
  baseURL = `https://notes.api.omkargosavi.com`;
}

// Create an instance of Axios with default configuration, including credentials
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

async function fetchData(input: string, config?: object) {
  try {
    const response = await axiosInstance(input, config);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response.data.error;
      if (error.response.status === 401) {
        throw new UnauthorizedError(errorMessage);
      } else if (error.response.status === 409) {
        throw new ConflictError(errorMessage);
      } else {
        throw Error(
          "Request failed with status: " +
            error.response.status +
            " message: " +
            errorMessage
        );
      }
    } else {
      throw error;
    }
  }
}

export async function getLoggedInUser(): Promise<User> {
  const response = await fetchData("/api/users", {
    method: "GET",
  });
  return response;
}

export interface SignUpCredentials {
  username: string;
  email: string;
  password: string;
}

export async function signUp(credentials: SignUpCredentials): Promise<User> {
  const response = await fetchData("/api/users/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: credentials, // Use 'data' instead of 'body' for Axios
  });
  return response;
}

export interface LoginCredentials {
  username: string;
  email: string;
  password: string;
}

export async function login(credentials: LoginCredentials): Promise<User> {
  const response = await fetchData("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: credentials, // Use 'data' instead of 'body' for Axios
  });
  return response;
}

export async function logout() {
  await fetchData("/api/users/logout", { method: "POST" });
}

export async function fetchNotes(): Promise<Note[]> {
  const response = await fetchData("/api/notes", { method: "GET" });
  return response;
}

export async function fetchNotesByPage(page: {
  page: number;
  search: string;
}): Promise<NoteObj[]> {
  const response = await fetchData("/api/notes/getNotesByPage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: page, // Use 'data' instead of 'body' for Axios
  });
  return response;
}

export const searchNotes = async (searchData: { search: string }) => {
  const response = await fetchData("/api/notes/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: searchData, // Use 'data' instead of 'body' for Axios
  });
  return response;
};

export interface NoteInput {
  title: string;
  text?: string;
}

export async function createNote(note: NoteInput): Promise<Note> {
  const response = await fetchData("/api/notes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: note, // Use 'data' instead of 'body' for Axios
  });
  return response;
}

export async function updateNote(
  noteId: string,
  note: NoteInput
): Promise<Note> {
  const response = await fetchData("/api/notes/" + noteId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    data: note, // Use 'data' instead of 'body' for Axios
  });
  return response;
}

export async function deleteNote(noteId: string) {
  await fetchData("/api/notes/" + noteId, { method: "DELETE" });
}
