import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

import { Note as NoteModel } from "../models/note";
import { NoteObj as NoteObjModel } from "../models/note";

import * as NotesApi from "../network/notes_api";
import styles from "../styles/NotesPage.module.css";
import styleUtils from "../styles/utils.module.css";
import AddEditNoteDialog from "./AddEditNoteDialog";
import Note from "./Note";
import PaginationComponent from "./Pagination";
import SearchBar from "./SearchBar";
import { debounce } from "lodash";
import Loader from "./Loader";
import { toast } from "react-toastify";

const NotesPageLoggedInView = () => {
  const [notes, setNotes] = useState<NoteModel[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [serachIndex, setSearchIndex] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const [notesLoading, setNotesLoading] = useState(true);
  const [overlayLoading, setOverlayLoading] = useState(false);

  const [showNotesLoadingError, setShowNotesLoadingError] = useState(false);

  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState<NoteModel | null>(null);

  const handlePageChange = (pageNumber: number) => {
    // Handle the page change event and update the page number
    setPageIndex(pageNumber);
    // loadNotes(pageNumber);
  };

  const handleSearchChange = async (searchString: string) => {
    setSearchIndex(searchString);
  };

  async function fetchData(page: number, search: string) {
    try {
      const response: NoteObjModel[] = await NotesApi.fetchNotesByPage({
        page: page,
        search: search,
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to propagate it to the caller
    }
  }

  async function loadNotes(page: number, search: string) {
    try {
      setShowNotesLoadingError(false);
      setNotesLoading(true);
      const response = await fetchData(page, search);
      setNotes(response[0]?.notes);
      setTotalPages(response[0]?.totalPages);
      setTotalItems(response[0]?.totalItems);
    } catch (error) {
      setShowNotesLoadingError(true);
    } finally {
      setNotesLoading(false);
    }
  }

  async function addDataUpdate(page: number, search: string) {
    try {
      const response = await fetchData(page, search);
      setTotalPages(response[0]?.totalPages);
      setTotalItems(response[0]?.totalItems);
    } catch (error) {
      setShowNotesLoadingError(true);
    } finally {
      setNotesLoading(false);
    }
  }

  async function deleteDataUpdate(
    page: number,
    search: string,
    delete_id?: any
  ) {
    try {
      const response = await fetchData(page, search);

      const newNotes = response[0]?.notes;

      const old_notes = notes.filter(
        (existingNote) => existingNote._id !== delete_id
      );
      const existingNoteIds = old_notes.map((note) => note._id);
      const filteredNewNotes = newNotes.filter(
        (newNote) => !existingNoteIds.includes(newNote._id)
      );

      setNotes([...old_notes, ...filteredNewNotes]);
      setTotalPages(response[0]?.totalPages);
      setTotalItems(response[0]?.totalItems);
    } catch (error) {
      setShowNotesLoadingError(true);
    } finally {
      setNotesLoading(false);
    }
  }

  useEffect(() => {
    loadNotes(pageIndex, serachIndex);
  }, [pageIndex, serachIndex]);

  async function deleteNote(note: NoteModel) {
    try {
      setOverlayLoading(true);
      await NotesApi.deleteNote(note._id);
      // loadNotes(pageIndex, serachIndex);
      setNotes(notes.filter((existingNote) => existingNote._id !== note._id));
      deleteDataUpdate(pageIndex, serachIndex, note._id); //for pagination  need to frtch data , because of server side rendering
      toast.success("Note deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        // className: "custom-toast", //
      });
      if (notes.length < 5) {
        setPageIndex(1);
      }
      setOverlayLoading(false);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  const notesGrid = (
    <div className={`container-fluid ${styles.notesGridContainer}`}>
      <Row xs={1} md={2} xl={3} className={`g-4 m-0 p-0 ${styles.notesRow}`}>
        {notes.map((note) => (
          <Col key={note._id}>
            <Note
              note={note}
              className={styles.note}
              onNoteClicked={setNoteToEdit}
              onDeleteNoteClicked={deleteNote}
            />
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <>
      {overlayLoading && <Loader />}
      <div
        className={`d-flex justify-content-between  mt-5 align-items-center gap-3 rounded p-3 bg-white  ${styles.customWidth}`}
      >
        <FiSearch />
        <SearchBar handleSearchChange={handleSearchChange} />
      </div>
      <div className="d-flex  justify-content-between w-100 mt-5">
        <Button
          className={`mb-4  ${styleUtils.flexCenter}`}
          onClick={() => setShowAddNoteDialog(true)}
        >
          <FaPlus />
          Add new note
        </Button>
        <div>Total Notes : {totalItems}</div>
      </div>

      <div className="notes-view">
        <div>
          {notesLoading && <Spinner animation="border" variant="primary" />}
          {showNotesLoadingError && (
            <p>Something went wrong. Please refresh the page.</p>
          )}
          {!notesLoading && !showNotesLoadingError && (
            <>
              {notes.length > 0 ? (
                notesGrid
              ) : (
                <div className="d-flex justify-content-center align-items-center h-notes-x">
                  No notes to display
                </div>
              )}
            </>
          )}
          <PaginationComponent
            onPageChange={handlePageChange}
            totalPages={totalPages}
          />
          {showAddNoteDialog && (
            <AddEditNoteDialog
              onDismiss={() => setShowAddNoteDialog(false)}
              onNoteSaved={(newNote) => {
                // here in new note we are getting addded note response from modal
                // per page only 6 are showing
                if (notes.length > 5) {
                  notes.pop();
                }
                setNotes([newNote, ...notes]); // updating it in array
                addDataUpdate(pageIndex, serachIndex); //for pagination  need to frtch data , because of server side rendering
                setShowAddNoteDialog(false);
              }}
            />
          )}
          {noteToEdit && (
            <AddEditNoteDialog
              noteToEdit={noteToEdit}
              onDismiss={() => setNoteToEdit(null)}
              onNoteSaved={(updatedNote) => {
                // here in new note we are getting updated note response from modal
                // loadNotes(pageIndex, serachIndex);
                // updating in array beow
                setNotes(
                  notes.map((existingNote) =>
                    existingNote._id === updatedNote._id
                      ? updatedNote
                      : existingNote
                  )
                );
                setNoteToEdit(null);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NotesPageLoggedInView;
