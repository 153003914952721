const NotFoundPage = () => {
  return (
    <div className="container home-view justify-content-center  ">
      <div className="text-center">
        <h1 className="display-4">Page Not Found</h1>
        <p className="lead">The requested page could not be found.</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
