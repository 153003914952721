import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <Container>
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
        <p className="col-md-4 mb-0 text-muted">© 2023 Notes </p>

        {/* <a
          href="/"
          className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto text-primary text-decoration-none fw-bold"
        >
          Notes.
        </a> */}

        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <a href="#" className="nav-link px-2 text-muted">
              Designed & Developed by Omkar Gosavi
            </a>
          </li>
          {/* <li className="nav-item">
            <a href="/" className="nav-link px-2 text-muted">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link px-2 text-muted">
              Features
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link px-2 text-muted">
              FAQs
            </a>
          </li>
          <li className="nav-item">
            <a href="/about" className="nav-link px-2 text-muted">
              About
            </a>
          </li> */}
        </ul>
      </footer>
    </Container>
  );
};

export default Footer;
