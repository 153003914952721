import { Container, Nav, Navbar } from "react-bootstrap";
import { User } from "../models/user";
import NavBarLoggedInView from "./NavBarLoggedInView";
import NavBarLoggedOutView from "./NavBarLoggedOutView";
import { Link } from "react-router-dom";

interface NavBarProps {
  loggedInUser: User | null;
  onSignUpClicked: () => void;
  onLoginClicked: () => void;
  onLogoutSuccessful: () => void;
}

const NavBar = ({
  loggedInUser,
  onSignUpClicked,
  onLoginClicked,
  onLogoutSuccessful,
}: NavBarProps) => {
  return (
    <>
      <Navbar expand="sm" className="custom-navbar mt-4">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src="/img/logo1.svg" alt="Logo" width={200} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-navbar" />
          <Navbar.Collapse id="main-navbar">
            <Nav>
              {/* <Nav.Link as={Link} to="/">
                Home
              </Nav.Link> */}
              {/* <Nav.Link as={Link} to="/privacy">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/privacy">
                Privacy
              </Nav.Link> */}
            </Nav>
            <Nav className="ms-auto">
              {loggedInUser ? (
                <NavBarLoggedInView
                  user={loggedInUser}
                  onLogoutSuccessful={onLogoutSuccessful}
                />
              ) : (
                <NavBarLoggedOutView
                  onLoginClicked={onLoginClicked}
                  onSignUpClicked={onSignUpClicked}
                />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
