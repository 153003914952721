import React, { useState, ChangeEvent } from "react";
import { debounce } from "lodash";

interface SearchBarProps {
  handleSearchChange: (searchQuery: string) => void;
}

const SearchBar = ({ handleSearchChange }: SearchBarProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Debounce the handleChange function to prevent rapid firing of events
  const debouncedHandleChange = debounce((value: string) => {
    handleSearchChange(value);
  }, 2000); // 300ms debounce time, adjust as needed

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    debouncedHandleChange(value); // Call the debounced function
  };

  return (
    <div className="w-100">
      <input
        type="text"
        value={searchQuery}
        onChange={handleChange}
        className="w-100 p-1"
        placeholder="Search Title"
      />
      {/* Render additional UI elements as needed */}
    </div>
  );
};

export default SearchBar;
