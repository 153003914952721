import { Button, Nav, Navbar } from "react-bootstrap";
import { User } from "../models/user";
import * as NotesApi from "../network/notes_api";
import { Link } from "react-router-dom";

interface NavBarLoggedInViewProps {
  user: User;
  onLogoutSuccessful: () => void;
}

const NavBarLoggedInView = ({
  user,
  onLogoutSuccessful,
}: NavBarLoggedInViewProps) => {
  async function logout() {
    try {
      await NotesApi.logout();
      onLogoutSuccessful();
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  return (
    <>
      <Nav>
        <Nav.Link as={Link} to="/" className="underline">
          <u>Home</u>
        </Nav.Link>
      </Nav>
      <Nav>
        <Nav.Link as={Link} to="/notes">
          <u>Notes</u>
        </Nav.Link>
      </Nav>

      <Nav>
        <Nav.Link as={Link} to="/notes">
          <Navbar.Text className="me-2">
            Signed in as: {user.username}
          </Navbar.Text>
        </Nav.Link>
      </Nav>
      <Nav>
        <Button onClick={logout}>Log out</Button>
      </Nav>
    </>
  );
};

export default NavBarLoggedInView;
