import { Nav } from "react-bootstrap";
import { User } from "../models/user";
import { Link } from "react-router-dom";

interface HomeProps {
  onSignUpClicked: () => void;
  loggedInUser: User | null;
}

const Home = ({ onSignUpClicked, loggedInUser }: HomeProps) => {
  return (
    <div className="home-view">
      <div className="row w-100">
        <div className="col-md-12 col-lg-6 mb-6 mb-md-0 home-col">
          <h1 className="display-5 fw-bold mb-4 position-relative home-title">
            Simple & free <br /> note taking app
          </h1>
          <ul className="d-flex mx-0 p-0 ">
            <li className="list-group-item">
              <span className="badge  me-2">React</span>
            </li>
            <li className="list-group-item">
              <span className="badge  me-2">Node</span>
            </li>
            <li className="list-group-item">
              <span className="badge   me-2">Typescript</span>
            </li>
            <li className="list-group-item">
              <span className="badge  me-2">MongoDB</span>
            </li>
          </ul>
          <p className="fs-4 mb-4">
            <ul className="feature-list">
              <li>
                <strong>Secure authentication :</strong> Uses express-session
                and cookies.
              </li>
              <li>TypeScript: Utilized for Backend & Frontend.</li>
              <li>
                <strong>Hosting :</strong> API hosted on AWS EC2 for backend
                control.
              </li>
              <li>
                <strong>Robust error handling :</strong> Handles HTTP errors
                effectively.
              </li>
              <li>
                <strong>Data Management :</strong> Supports Create, Read,
                Update, Delete.
              </li>
              <li>
                <strong>Efficient pagination :</strong> Handles large data sets
                efficiently.
              </li>
              <li>
                <strong>Powerful search functionality :</strong> Enables quick
                data retrieval.
              </li>
              <li>
                <strong>Clean interface :</strong> Provides a user-friendly
                experience.
              </li>
              <li>
                <strong>Protected routes :</strong> Ensures secure access
                control.
              </li>
            </ul>
          </p>
          {!loggedInUser ? (
            <div className="btn btn-primary btn-lg" onClick={onSignUpClicked}>
              Try Notes, it's FREE!
            </div>
          ) : (
            <Link to="/notes" className="">
              <div className="btn btn-primary btn-lg">
                Get Started with Notes
              </div>
            </Link>
          )}
        </div>
        <div className="col-md-12 col-lg-6 mb-6 mb-md-0 home"></div>
      </div>
    </div>
  );
};

export default Home;
