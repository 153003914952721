import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import LoginModal from "./components/LoginModal";
import NavBar from "./components/NavBar";
import SignUpModal from "./components/SignUpModal";
import { User } from "./models/user";
import * as NotesApi from "./network/notes_api";
import NotesPage from "./pages/NotesPage";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyPage from "./pages/PrivacyPage";
import styles from "./styles/App.module.css";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for the default styles
import { ToastContainer } from "react-toastify";

interface PrivateRouteProps {
  loading: boolean;
  loggedInUser: User | null;
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  loading,
  loggedInUser,
  children,
}) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  return loggedInUser ? children : <Navigate to="/" />;
};

function App() {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(true); // state for loading

  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
        const user = await NotesApi.getLoggedInUser();
        setLoggedInUser(user);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchLoggedInUser();
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <div>
        <NavBar
          loggedInUser={loggedInUser}
          onLoginClicked={() => setShowLoginModal(true)}
          onSignUpClicked={() => setShowSignUpModal(true)}
          onLogoutSuccessful={() => setLoggedInUser(null)}
        />
        <Container className={styles.pageContainer}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  loggedInUser={loggedInUser}
                  onSignUpClicked={() => setShowSignUpModal(true)}
                />
              }
            />

            <Route
              path="/notes"
              element={
                <PrivateRoute loggedInUser={loggedInUser} loading={loading}>
                  <NotesPage loggedInUser={loggedInUser} />
                </PrivateRoute>
              }
            />

            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </Container>
        {showSignUpModal && (
          <SignUpModal
            onDismiss={() => setShowSignUpModal(false)}
            onSignUpSuccessful={(user) => {
              setLoggedInUser(user);
              setShowSignUpModal(false);
            }}
          />
        )}
        {showLoginModal && (
          <LoginModal
            onDismiss={() => setShowLoginModal(false)}
            onLoginSuccessful={(user) => {
              setLoggedInUser(user);
              setShowLoginModal(false);
            }}
          />
        )}
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
